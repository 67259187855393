import { AxiosResponse } from 'axios'
import { ReportsEndpoints } from '../reportsEndpoints'
import { requests } from '@/libs/axios/axios'
import { reAuditReportData } from '../reports.interfaces'

const updateAuditData = async (data: reAuditReportData): Promise<AxiosResponse> => {
  return requests.post(`${ReportsEndpoints.RE_AUDIT_REPORTS}`, data)
}

export default updateAuditData
